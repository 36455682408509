import { createContext, useContext } from 'react';
import { isMobile } from '../services/browser';
import { convertSizeForOrientation } from '../styles/helpers';
import { CalculateRemValue, IDeviceInfoContext } from '../types';

const isMobileDevice = isMobile();

const PORTRAIT_MIN_RATIO = 0.58;
const PORTRAIT_MAX_RATIO = 0.8;
const LANDSCAPE_MIN_RATIO = 2.084;
const LANDSCAPE_MAX_RATIO = 1.755;
const MAX_GAME_VIEW_WIDTH = 414;

const gameViewWidth = (isGame: boolean): number => {
  if (isGame) {
    return Math.min(window.innerWidth, MAX_GAME_VIEW_WIDTH);
  }

  return window.innerWidth;
};

const calculateRatio = (isGame: boolean) => gameViewWidth(isGame) / window.innerHeight;

const isDeviceLandscape = (isGame: boolean) => (isGame && !isMobileDevice ? false : calculateRatio(isGame) > PORTRAIT_MAX_RATIO);

const calculateContentMaxWidth = (isLandscape: boolean, isGame: boolean) => {
  const ratio = calculateRatio(isGame);

  if (isLandscape) {
    return Math.min(gameViewWidth(isGame), (LANDSCAPE_MIN_RATIO / ratio) * gameViewWidth(isGame));
  }

  return ratio > PORTRAIT_MIN_RATIO ? window.innerHeight * PORTRAIT_MIN_RATIO : gameViewWidth(isGame);
};

export const getDeviceInfo = (isGame: boolean = false) => {
  const ratio = calculateRatio(isGame);
  const isLandscape = isDeviceLandscape(isGame);
  const contentMaxWidth = calculateContentMaxWidth(isLandscape, isGame);
  const contentMaxHeight = isLandscape ? contentMaxWidth / LANDSCAPE_MAX_RATIO : window.innerHeight;

  return {
    width: gameViewWidth(isGame),
    height: window.innerHeight,
    contentMaxWidth,
    contentMaxHeight,
    isLandscape,
    ratio,
  };
};

const createDefaultValue = (): IDeviceInfoContext => {
  const deviceInfo = getDeviceInfo();
  const calculateRemValue: CalculateRemValue = (portraitValue) => convertSizeForOrientation(portraitValue, deviceInfo.isLandscape);

  return {
    ...deviceInfo,
    isResizing: false,
    calculateRemValue,
  };
};

export const DeviceInfoContext = createContext<IDeviceInfoContext>(createDefaultValue());

export const useDeviceInfo = () => useContext(DeviceInfoContext);
