import {
  all, call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import {
  IGetCurrenciesRequestFilterParams,
  IGetCurrenciesResponseBody,
} from '../types';
import {
  postCurrencyRequest,
  getCurrenciesRequest,
  putCurrencyRequest,
  getAllCurrenciesRequest,
} from '../api';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  postCurrency,
  getCurrencies,
  getCurrenciesError,
  getCurrenciesSuccess,
  mergeCurrencyDialogForm,
  setCurrencyDialogForm,
  putCurrency,
  getAllCurrencies,
  getAllCurrenciesSuccess,
} from './actions';

function* handleGetCurrenciesRequest(requestParams: IGetCurrenciesRequestFilterParams) {
  const { data } = yield call(getCurrenciesRequest, requestParams);

  return data;
}

function* handleGetAllCurrenciesRequest() {
  const { data } = yield call(getAllCurrenciesRequest);

  return data;
}

function* handleRefreshCurrencies() {
  yield put(getCurrencies(queryString.parse(window.location.search)));
}

function* handlePostCurrency({ payload }: ReturnType<typeof postCurrency>) {
  try {
    yield put(mergeCurrencyDialogForm({
      isLoading: true,
    }));

    yield call(postCurrencyRequest, payload);
    yield put(setCurrencyDialogForm());
    yield call(toast.success, 'The currency has been successfully created');
    yield handleRefreshCurrencies();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeCurrencyDialogForm({
      isLoading: false,
    }));
  }
}

export function* handleGetCurrencies({ payload }: ReturnType<typeof getCurrencies>) {
  try {
    const data: IGetCurrenciesResponseBody = yield handleGetCurrenciesRequest(payload);

    yield put(getCurrenciesSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getCurrenciesError());
  }
}

export function* handleGetAllCurrencies() {
  try {
    const data: IGetCurrenciesResponseBody = yield handleGetAllCurrenciesRequest();

    yield put(getAllCurrenciesSuccess(data));
  } catch (error: any) {
    yield handleError(error);
  }
}

function* handlePutCurrency({ payload }: ReturnType<typeof putCurrency>) {
  try {
    yield put(mergeCurrencyDialogForm({
      isLoading: true,
    }));

    yield call(putCurrencyRequest, payload);
    yield put(setCurrencyDialogForm());
    yield call(toast.success, `The currency ${payload.currency} has been successfully updated`);
    yield handleRefreshCurrencies();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeCurrencyDialogForm({
      isLoading: false,
    }));
  }
}

export function* currenciesSagas(): any {
  yield all([
    yield takeEvery(
      postCurrency,
      handlePostCurrency,
    ),
    yield takeLatest(
      getCurrencies,
      handleGetCurrencies,
    ),
    yield takeLatest(
      getAllCurrencies,
      handleGetAllCurrencies,
    ),
    yield takeEvery(
      putCurrency,
      handlePutCurrency,
    ),
  ]);
}
