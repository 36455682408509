import { useCallback, useRef } from 'react';
import {
  CSRTCVideoPlayerSessionStatus,
  CSRTCVideoPlayerStatus,
  HLSVideoPlayerStatus,
  VideoStatusChangeType,
  VideoPlayerType,
  PubSubUserEventNotification,
  IUserEventNotification,
} from '../types';

interface IUseVideoStreamLog {
  playerType: VideoPlayerType;
  sendUserEventNotification: (data: IUserEventNotification) => void;
}

export const useVideoStreamLog = ({ playerType, sendUserEventNotification }: IUseVideoStreamLog) => {
  const videoPlayerStatusRef = useRef('');
  const csrtcVideoPlayerSessionStatusRef = useRef('');

  const handleHLSVideoPlayerStatusChange = useCallback((status: HLSVideoPlayerStatus) => {
    if (status !== videoPlayerStatusRef.current) {
      videoPlayerStatusRef.current = status;
      console.log(VideoStatusChangeType.HLSPlayerStreamStatus, 'VideoStatusChangeType.HLSPlayerStreamStatus,VideoStatusChangeType.HLSPlayerStreamStatus,VideoStatusChangeType.HLSPlayerStreamStatus,VideoStatusChangeType.HLSPlayerStreamStatus,');
      sendUserEventNotification({
        type: PubSubUserEventNotification.video,
        data: {
          type: VideoStatusChangeType.HLSPlayerStreamStatus,
          status,
        },
      });
    }
  }, [sendUserEventNotification]);

  const handleSCRTCVideoPlayerSessionStatusChange = useCallback((status: CSRTCVideoPlayerSessionStatus) => {
    if (status !== csrtcVideoPlayerSessionStatusRef.current) {
      csrtcVideoPlayerSessionStatusRef.current = status;
      console.log(VideoStatusChangeType.CSRTCPlayerSessionStatus, 'VideoStatusChangeType.CSRTCPlayerSessionStatusVideoStatusChangeType.CSRTCPlayerSessionStatusVideoStatusChangeType.CSRTCPlayerSessionStatusVideoStatusChangeType.CSRTCPlayerSessionStatus');
      sendUserEventNotification({
        type: PubSubUserEventNotification.video,
        data: {
          type: VideoStatusChangeType.CSRTCPlayerSessionStatus,
          status,
        },
      });
    }
  }, [sendUserEventNotification]);

  const handleCSRTCVideoPlayerStreamStatusChange = useCallback((status: CSRTCVideoPlayerStatus) => {
    if (status !== videoPlayerStatusRef.current) {
      videoPlayerStatusRef.current = status;
      sendUserEventNotification({
        type: PubSubUserEventNotification.video,
        data: {
          type: VideoStatusChangeType.CSRTCPlayerStreamStatus,
          status,
        },
      });
    }
  }, [sendUserEventNotification]);

  const handleLogEvent = useCallback((message: string) => {
    sendUserEventNotification({
      type: PubSubUserEventNotification.video,
      data: {
        type: playerType,
        message,
      },
    });
  }, [playerType, sendUserEventNotification]);

  return {
    handleHLSVideoPlayerStatusChange,
    handleSCRTCVideoPlayerSessionStatusChange,
    handleCSRTCVideoPlayerStreamStatusChange,
    handleLogEvent,
  };
};
