import { createAction } from '@reduxjs/toolkit';
import {
  IPostCurrencyRequestBody,
  IGetCurrenciesRequestFilterParams,
  IGetCurrenciesResponseBody,
  ICurrencyDialogFormState,
  IPutCurrencyRequestBody,
} from '../types';

export const postCurrency = createAction<IPostCurrencyRequestBody>('CURRENCIES/POST_CURRENCY');

export const getCurrencies = createAction<IGetCurrenciesRequestFilterParams>('CURRENCIES/GET_CURRENCIES');
export const getCurrenciesSuccess = createAction<IGetCurrenciesResponseBody>('CURRENCIES/GET_CURRENCIES/SUCCESS');
export const getCurrenciesError = createAction('CURRENCIES/GET_CURRENCIES/ERROR');

export const getAllCurrencies = createAction('CURRENCIES/GET_ALL_CURRENCIES');
export const getAllCurrenciesSuccess = createAction<IGetCurrenciesResponseBody>('CURRENCIES/GET_ALL_CURRENCIES/SUCCESS');

export const setCurrencyDialogForm = createAction<Partial<ICurrencyDialogFormState> | undefined>('CURRENCIES/SET_DIALOG_FORM');
export const mergeCurrencyDialogForm = createAction<Partial<ICurrencyDialogFormState> | undefined>('CURRENCIES/MERGE_DIALOG_FORM');

export const putCurrency = createAction<IPutCurrencyRequestBody>('CURRENCIES/PUT_CURRENCY');
