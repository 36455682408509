import { createReducer } from '@reduxjs/toolkit';
import {
  getCurrencies,
  getCurrenciesError,
  getCurrenciesSuccess,
  setCurrencyDialogForm,
  mergeCurrencyDialogForm,
  getAllCurrencies,
  getAllCurrenciesSuccess,
} from './actions';
import { ICurrenciesReducer } from '../types';

export const initialState: ICurrenciesReducer = {
  isLoading: false,
  total: 0,
  records: [],
  allCurrencies: [],
  dialogForm: {
    isOpen: false,
    isLoading: false,
  },
};

export const currenciesReducer = createReducer(initialState, (builder) => {
  builder.addCase(getCurrencies, (state) => ({
    ...state,
    total: 0,
    records: [],
    isLoading: true,
  }));
  builder.addCase(getCurrenciesSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
  builder.addCase(getCurrenciesError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(getAllCurrencies, (state) => ({
    ...state,
    allCurrencies: [],
  }));
  builder.addCase(getAllCurrenciesSuccess, (state, { payload }) => ({
    ...state,
    allCurrencies: payload.records,
  }));

  builder.addCase(setCurrencyDialogForm, (state, { payload }) => ({
    ...state,
    dialogForm: {
      ...initialState.dialogForm,
      ...payload,
    },
  }));
  builder.addCase(mergeCurrencyDialogForm, (state, { payload }) => ({
    ...state,
    dialogForm: {
      ...state.dialogForm,
      ...payload,
    },
  }));
});
