export interface IOrientationData {
  isLandscape: boolean;
}

export interface IDeviceInfo extends IOrientationData {
  width: number;
  height: number;
  contentMaxWidth: number;
  contentMaxHeight: number;
  ratio: number;
}

export interface IDeviceInfoContext extends IDeviceInfo {
  isResizing: boolean;
  calculateRemValue: CalculateRemValue;
}

export type CalculateRemValue = (portraitValue: string | number) => string;

export enum GameId {
  CLAW = 'claw101',
  PUSHER = 'CD_Lobby',
  ROULETTE = 'clawRoulette'
}
