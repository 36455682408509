import { SortOrder, VoucherStatus } from 'arcadia-common-fe';
import { TransactionStatus } from '../modules/transactions/types';
import { SessionStatus } from '../modules/sessions/types';
import { PlayerStatus } from '../modules/players/types';
import { SessionMode } from '../types/sessions';
import { AlertStatus } from '../types/alerts';

export const { API_HOST } = process.env;

export enum LocalStorageKeys {
  accessToken = 'accessToken',
  user = 'user',
}

export const ALERTS_UPDATE_INTERVAL = 30000;

export const SESSION_UPDATE_INTERVAL = 30000;
export const MACHINE_NAME_UPDATE_INTERVAL = 30000;
export const OPERATOR_NAME_UPDATE_INTERVAL = 30000;
export const GROUP_NAME_UPDATE_INTERVAL = 30000;



export const modulesInitialFilters = {
  sessions: {
    [SessionMode.active]: {
      status: [
        SessionStatus.viewer,
        SessionStatus.playing,
        SessionStatus.autoplay,
        SessionStatus.queue,
        SessionStatus.forcedAutoplay,
        SessionStatus.viewerBetBehind,
        SessionStatus.queueBetBehind,
        SessionStatus.reBuy,
        SessionStatus.terminating,
      ],
      sortBy: 'status',
      sortOrder: SortOrder.desc,
      mode: SessionMode.active,
    },
    [SessionMode.archive]: {
      status: [],
      sortBy: 'startDate',
      sortOrder: SortOrder.desc,
      mode: SessionMode.archive,
      get endDate() {
        return new Date().toISOString();
      },
    },
  },
  players: {
    status: [PlayerStatus.inPlay, PlayerStatus.active],
    sortBy: 'status',
    sortOrder: SortOrder.asc,
  },
  groups: {
    sortBy: 'name',
    sortOrder: SortOrder.asc,
  },
  machines: {
    sortBy: 'status',
    sortOrder: SortOrder.asc,
  },
  vouchers: {
    status: [VoucherStatus.pending],
    sortBy: 'grantedDate',
    sortOrder: SortOrder.desc,
  },
  operators: {
    sortBy: 'name',
    sortOrder: SortOrder.asc,
  },
  cameras: {
    sortBy: 'id',
    sortOrder: SortOrder.asc,
  },
  alerts: {
    status: [AlertStatus.active],
  },
  disputes: {
    sortBy: 'status',
    sortOrder: SortOrder.asc,
  },
  monitoring: {
    sortBy: 'status',
    sortOrder: SortOrder.asc,
  },
  administration: {
    sortBy: 'status',
    sortOrder: SortOrder.asc,
  },
  transactions: {
    status: [TransactionStatus.failed],
    sortBy: 'createDate',
    sortOrder: SortOrder.desc,
  },
  cms: {
    sortBy: 'assetType',
    sortOrder: SortOrder.asc,
  },
  currency: {
    sortBy: 'effectiveFrom',
    sortOrder: SortOrder.desc,
  },
  credentials: {
    sortBy: 'displayName',
    sortOrder: SortOrder.asc,
  },
  brands: {
    sortBy: 'name',
    sortOrder: SortOrder.asc,
  },
};
