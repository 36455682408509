/* eslint-disable no-console */
import {
  useEffect, useRef, useState, useCallback,
} from 'react';
import {
  CSRTCVideoFailedEventReason,
  IUserEventNotification,
  IVideoFailedEvent,
  VideoPlayerType,
  VideoStreamQuality,
  VideoStreamStatus,
} from '../types';
import { useVideoStreamLog } from './useVideoStreamLog';
import { useConnectionSpeedMeasure } from '../../../hooks/useConnectionSpeedMeasure';
import { usePrevious } from '../../../hooks';

const VIDEO_FAILED_TIMEOUT = 5000;
const VIDEO_TIME_TRACKING_INTERVAL_MS = 250;
const VIDEO_DELAY_FREQUENCY_LIMIT = 8;
const VIDEO_PAUSE_DETECTION_DELAY = 1000;

const lowBandwidthDetectionConfig = {
  trackingPeriodInMS: 60 * 1000,
  maxLoadingsPerTrackingPeriod: 5,
};

interface IUseVideoStream {
  activeVideoPlayerType: VideoPlayerType;
  videoNodeRef: HTMLDivElement | null;
  sendUserEventNotification: (data: IUserEventNotification) => void;
  sendVideoFailedEvent: (data: IVideoFailedEvent) => void;
}

export const useVideoStream = ({
  activeVideoPlayerType, videoNodeRef, sendVideoFailedEvent, sendUserEventNotification,
}: IUseVideoStream) => {
  const sendVideoFailedEventTimeoutRef = useRef(0);
  const videoTimingsTrackingIntervalRef = useRef(0);
  const lowBandwidthDetectionLoadingsCounterIntervalRef = useRef(0);
  const videoDelayTickRef = useRef(0);
  const videoPauseDetectionDelayRef = useRef(0);
  const [videoPlayerPreviousTime, setVideoPlayerPreviousTime] = useState(0);
  const [videoStreamStatus, setVideoStreamStatus] = useState(VideoStreamStatus.loading);
  const [lowBandwidthDetectionLoadingsPerPeriod, setLowBandwidthDetectionLoadingsPerPeriod] = useState(0);
  const [quality, setQuality] = useState(VideoStreamQuality.high);
  const latestConnectionSpeedsRef = useRef<number[]>([]);
  const [currentVideoNode, setCurrentVideoNode] = useState<HTMLVideoElement | null | undefined>()

  const videoNode = videoNodeRef?.querySelector('video');
  const previousVideoNode = usePrevious(videoNode);

  const {
    handleLogEvent,
  } = useVideoStreamLog({ playerType: activeVideoPlayerType, sendUserEventNotification });

  const {
    latestConnectionSpeeds,
    handleMeasureConnectionSpeed,
  } = useConnectionSpeedMeasure();

  const handleSetLowQuality = useCallback((logMessage: string) => {
    setQuality(VideoStreamQuality.low);
    handleLogEvent(logMessage);
    console.log(logMessage);
  }, [handleLogEvent]);


  useEffect(() => {
    setCurrentVideoNode(videoNode || previousVideoNode)
  }, [videoNode, previousVideoNode]);

  useEffect(() => {
    videoTimingsTrackingIntervalRef.current = window.setInterval(() => {
      const videoPlayerTime = currentVideoNode?.currentTime || 0;

      if (
        videoPauseDetectionDelayRef.current >= VIDEO_PAUSE_DETECTION_DELAY
        && videoStreamStatus !== VideoStreamStatus.paused
        && currentVideoNode?.paused
      ) {
        setVideoStreamStatus(VideoStreamStatus.paused);
      } else {
        videoPauseDetectionDelayRef.current += VIDEO_TIME_TRACKING_INTERVAL_MS;
      }

      if (videoStreamStatus === VideoStreamStatus.paused && !currentVideoNode?.paused) {
        setVideoStreamStatus(VideoStreamStatus.loading);
      }

      if (videoStreamStatus !== VideoStreamStatus.paused) {
        if (videoPlayerTime !== videoPlayerPreviousTime) {
          setVideoPlayerPreviousTime(videoPlayerTime);
        }

        if (videoPlayerPreviousTime === videoPlayerTime || videoPlayerTime === 0) {
          videoDelayTickRef.current += 1;

          if (videoDelayTickRef.current >= VIDEO_DELAY_FREQUENCY_LIMIT && videoStreamStatus !== VideoStreamStatus.loading) {
            setVideoStreamStatus(VideoStreamStatus.loading);
          }
        } else {
          videoDelayTickRef.current = 0;

          if (videoStreamStatus !== VideoStreamStatus.playing) {
            setVideoStreamStatus(VideoStreamStatus.playing);
          }
        }
      }
    }, VIDEO_TIME_TRACKING_INTERVAL_MS);

    return () => {
      clearInterval(videoTimingsTrackingIntervalRef.current);
    };
  }, [videoStreamStatus, videoPlayerPreviousTime, currentVideoNode]);

  useEffect(() => {
    setVideoStreamStatus(VideoStreamStatus.loading);
  }, [quality, activeVideoPlayerType]);

  useEffect(() => {
    if (videoStreamStatus === VideoStreamStatus.loading) {
      setLowBandwidthDetectionLoadingsPerPeriod((prevState) => prevState + 1);
    }
  }, [videoStreamStatus]);

  useEffect(() => {
    latestConnectionSpeedsRef.current = latestConnectionSpeeds;
  }, [latestConnectionSpeeds]);

  useEffect(() => {
    clearTimeout(sendVideoFailedEventTimeoutRef.current);

    if (videoStreamStatus === VideoStreamStatus.loading) {
      handleMeasureConnectionSpeed();

      if (quality === VideoStreamQuality.low) {
        console.log(`Video stream failed event will be sent in ${VIDEO_FAILED_TIMEOUT}ms`);
      } else {
        console.log(`Video stream will fallback to the low quality if video doesn't continue playing in next ${VIDEO_FAILED_TIMEOUT}ms`);
      }

      sendVideoFailedEventTimeoutRef.current = window.setTimeout(() => {
        if (quality !== VideoStreamQuality.low) {
          handleSetLowQuality(
            `Video stream quality is changed to ${VideoStreamQuality.low}
            after ${VIDEO_FAILED_TIMEOUT}ms with ${videoDelayTickRef.current} video tick delays`,
          );
        } else {
          sendVideoFailedEvent({ connectionSpeedKbps: latestConnectionSpeeds, reason: CSRTCVideoFailedEventReason.poorConnection });

          console.log(`Video stream failed event is sent after ${VIDEO_FAILED_TIMEOUT}ms`);
        }
      }, VIDEO_FAILED_TIMEOUT);
    }

    return () => {
      clearTimeout(sendVideoFailedEventTimeoutRef.current);
    };
  }, [activeVideoPlayerType, videoStreamStatus, quality]);

  useEffect(() => {
    if (
      quality !== VideoStreamQuality.low
      && lowBandwidthDetectionLoadingsPerPeriod >= lowBandwidthDetectionConfig.maxLoadingsPerTrackingPeriod
    ) {
      handleSetLowQuality(
        `Video stream quality is changed to ${VideoStreamQuality.low}
        after ${lowBandwidthDetectionLoadingsPerPeriod} low bandwidth detection loadings per period`,
      );
      handleMeasureConnectionSpeed();
    }
  }, [quality, lowBandwidthDetectionLoadingsPerPeriod]);

  useEffect(() => {
    lowBandwidthDetectionLoadingsCounterIntervalRef.current = window.setInterval(() => {
      setLowBandwidthDetectionLoadingsPerPeriod(0);
    }, lowBandwidthDetectionConfig.trackingPeriodInMS);

    return () => {
      clearTimeout(lowBandwidthDetectionLoadingsCounterIntervalRef.current);
    };
  }, []);

  return {
    quality,
    videoStreamStatus,
  };
};
